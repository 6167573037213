import React from 'react';
import tw from 'twin.macro';
import Layout from '../components/Layout';

const Container = tw.div`bg-gray-100 h-screen mt-16`;

const PaymentSuccess = ({
  heading = 'Payment Done!',
  title = 'Thank you for your business and your trust. It is our pleasure to work with you.',
}) => {
  return (
    <Layout>
      <Container>
        <div tw=" p-6  md:mx-auto">
          {' '}
          <svg viewBox="0 0 24 24" tw="text-green-600 w-16 h-16 mx-auto my-6">
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            >
              {}
            </path>
            {}
          </svg>
          <div tw="text-center">
            <h3 tw="md:text-2xl text-base text-gray-900 font-semibold text-center">{heading}</h3>
            <p tw="text-gray-600 my-2">{title}</p>
            <p> Please book your appointment with tax Expert.</p>
            <div tw="py-10 text-center">
              <a
                href="https://meetings.hubspot.com/narinder-deol"
                tw="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
              >
                Book Appointment
              </a>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default PaymentSuccess;
